import * as React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const PreviewPage = () => (
    <Layout>
        <h1>Loading preview…</h1>
    </Layout>
)

export default withPrismicPreviewResolver(PreviewPage)
